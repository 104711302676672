<template>
 <div class="bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
  <div class="max-w-7xl mx-auto 2xl:px-0 px-4">
   <form
    @submit.prevent="
     this.openUpdateForm ? putCallQueue(editData.id) : postCallQueue()
    "
    method="POST">
    <div class="space-y-10">
     <div class="pt-5 border-b border-gray-200 pb-12">
      <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
       <div class="sm:col-span-6">
        <label
         for="name"
         class="capitalize block text-sm font-medium leading-6 text-gray-900">
         {{ $t("name") }}
        </label>
        <div class="mt-2">
         <input
          required
          v-model="this.form.name"
          type="text"
          name="name"
          id="name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
        </div>
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="type"
         class="block text-sm font-semibold leading-6 text-gray-900 capitalize">
         {{ $t("type") }}*
        </label>
        <div class="mt-2">
         <select
          required
          v-model="this.type"
          min="0"
          name="type"
          id="type"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6">
          <option value="queue">{{ $t("queue") }}</option>
          <option value="did">{{ $t("did") }}</option>
         </select>
        </div>
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="number"
         class="block text-sm font-semibold leading-6 text-gray-900 capitalize">
         {{ $t("number") }}*
        </label>
        <div class="w-full">
         <div class="relative">
          <!-- <div
           class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
           <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div> -->
          <div class="mt-2">
           <vue-select
            v-model="searchNumber"
            :options="selectOptionsNumbers"
            label-by="label"
            value-by="value"
            :close-on-select="true"
            :disabled="selectOptionsNumbers.length <= 0"
            searchable
            clear-on-select
            :multiple="false"
            :placeholder="searchNumber ? searchNumber : $t('search')"
            class="text-left block bg-white rounded-md py-1 pl-2 pr-3 placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400">
           </vue-select>
          </div>
         </div>
        </div>
        <!-- <div class="mt-2">
         <input
          required
          v-model="this.form.number"
          min="0"
          type="number"
          name="number"
          id="number"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
        </div> -->
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="default_destination_type"
         class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("default destination type") }}
        </label>
        <div class="mt-2">
         <select
          v-model="destinationType"
          min="0"
          name="default_destination_type"
          id="default_destination_type"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6">
          <option
           v-for="type in dest_type"
           :key="type.value"
           :value="type.value">
           {{ $t(type.name) }}
          </option>
         </select>
        </div>
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="default_destination"
         class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("destination number") }}
        </label>
        <div class="w-full">
         <div class="relative">
          <!-- <div
           class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
           <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div> -->
          <div class="mt-2">
           <vue-select
            v-if="
             !destinationType ||
             destinationType === 'extension' ||
             destinationType === 'callQueue' ||
             destinationType === 'voicemail'
            "
            v-model="searchDestNumber"
            :options="selectOptions"
            label-by="label"
            :close-on-select="true"
            :disabled="selectOptions.length <= 0"
            searchable
            clear-on-select
            :multiple="false"
            :placeholder="searchDestNumber ? searchDestNumber : $t('search')"
            class="text-left block bg-white rounded-md py-1 pl-2 pr-3 placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400">
           </vue-select>
           <div v-else-if="destinationType === 'externalNumber'">
            <input
             v-model="searchDestNumber"
             type="text"
             name="default_destination"
             id="default_destination"
             :class="
              ({ 'is-invalid': phoneError },
              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6')
             " />
            <span v-if="phoneError" class="error">{{ phoneError }}</span>
           </div>
           <div v-else-if="destinationType === 'endCall'">
            <input
             v-model="searchDestNumber"
             type="text"
             name="default_destination"
             id="default_destination"
             disabled=""
             class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
           </div>
          </div>
         </div>
        </div>
        <!-- <div class="mt-2">
         <input
          v-model="destination"
          min="0"
          type="number"
          name="default_destination"
          id="default_destination"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
        </div> -->
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="callQueueGroupId"
         class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("inbound rules groups") }}
        </label>
        <div class="mt-2">
         <select
          v-model="this.form.call_queue_group_id"
          type="number"
          id="callQueueGroupId"
          name="callQueueGroupId"
          class="rounded block w-full shadow-sm focus:encom_border_input sm:text-sm border-gray-300">
          <option
           v-for="callQueueGroup in this.callQueuesGroups"
           :key="callQueueGroup.id"
           :value="callQueueGroup.id">
           {{ callQueueGroup.name }}
          </option>
         </select>
        </div>
       </div>
       <div
        class="sm:col-span-3"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="code"
         class="block text-sm font-medium leading-6 text-gray-900">
         Code
        </label>
        <div class="mt-2">
         <input
          v-model="this.form.code"
          type="text"
          name="code"
          id="code"
          class="rounded block w-full shadow-sm focus:encom_border_input sm:text-sm border-gray-300" />
        </div>
       </div>
       <div
        class="col-span-6 flex items-center gap-3 justify-start"
        v-if="
         this.title === $t('callqueue.newFormTitle') ||
         this.title === $t('callqueue.updateFormTitle')
        ">
        <label
         for="active"
         class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("active") }}
        </label>
        <div class="">
         <input
          v-model="this.form.active"
          type="checkbox"
          name="active"
          id="active"
          class="rounded block shadow-sm focus:encom_border_input sm:text-sm border-gray-300" />
        </div>
       </div>
      </div>
     </div>
    </div>

    <div class="mt-6 pb-12 flex items-center justify-end gap-x-6">
     <button
      type="button"
      @click="this.$emit('closeForm')"
      class="rounded-md bg-white px-3 py-2 border border-gray-300 shadow-sm text-sm font-semibold text-gray-900 hover:bg-gray-50">
      {{ $t("ticket.cancel") }}
     </button>
     <button
      type="submit"
      class="rounded-md body__table--header px-3 py-2 text-sm font-semibold text-white shadow-sm">
      {{ $t("ticket.send") }}
     </button>
    </div>
   </form>
  </div>
 </div>
 <!--  <transition
  enter-active-class="transition ease-out duration-200"
  enter-from-class="opacity-0 -translate-y-1"
  enter-to-class="opacity-100 translate-y-0"
  leave-active-class="transition ease-in duration-150"
  leave-from-class="opacity-100 translate-y-0"
  leave-to-class="opacity-0 -translate-y-1"
 >
  

  <div>
   <div class=" transform">
    <div class="max-w-auto mx-auto bg-white px-4 py-2">
     <form
      @submit.prevent="this.openUpdateForm ? putCallQueue(editData.id) : postCallQueue()"
      class="space-y-8 divide-y divide-gray-200 form_add_new_cti_padding"
      method="POST"
     >
      <div class="pt-4 space-y-6 sm:pt-4 sm:space-y-5">
       <div>
        <div class="inline-flex">
         <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ this.title }}
         </h3>
        </div>

        <p class="mt-1 max-w-2xl text-sm text-gray-500">
         {{ this.subTitle }}
        </p>
       </div>
       <div class="space-y-6 sm:space-y-5">
        <div
         v-if="this.title === $t('callqueue.newFormTitle')"
         class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
        >
         <label for="number" class="block text-sm font-bold text-gray-700 sm:mt-px sm:pt-2">
          {{ $t("callqueueTable.number") }}
         </label>
         <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input
           v-model="this.form.number"
           required
           min="0"
           type="number"
           name="number"
           id="number"
           class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input sm:max-w-xs sm:text-sm border-gray-300"
          />
         </div>
        </div>

        <div
         class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
        >
         <label for="name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {{ $t("callqueueTable.name") }}
         </label>
         <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input
           v-model="this.form.name"
           type="text"
           id="name"
           name="name"
           class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input sm:max-w-xs sm:text-sm border-gray-300"
          />
         </div>
        </div>
        <div
         v-if="this.title === $t('callqueue.newFormTitle')"
         class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
        >
         <label
          for="call_queue_group_id"
          class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
         >
          {{ $t("sidebar.specialNavigation.callqueuegroup") }}
         </label>
         <div class="mt-1 sm:mt-0 sm:col-span-2">
          <select
           v-model="this.form.call_queue_group_id"
           type="number"
           id="callQueueGroupId"
           name="callQueueGroupId"
           class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input sm:max-w-xs sm:text-sm border-gray-300"
          >
           <option value="">None</option>
           <option
            v-for="callQueueGroup in this.callQueuesGroups"
            :key="callQueueGroup.id"
            :value="callQueueGroup.id"
           >
            {{ callQueueGroup.name }}
           </option>
          </select>
         </div>
        </div>
        <div
         v-if="this.title === $t('callqueue.newFormTitle')"
         class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
        >
         <label for="code" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Code
         </label>
         <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input
           v-model="this.form.code"
           type="text"
           name="code"
           id="code"
           class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input sm:max-w-xs sm:text-sm border-gray-300"
          />
         </div>
        </div>
       </div>
      </div>
      <div class="pt-5">
       <div class="flex justify-end">
        <a
         href="javascript:void(0)"
         v-on:click="this.$emit('closeForm')"
         class="rounded bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
         {{ $t("ticket.cancel") }}
        </a>
        <button
         type="submit"
         class="rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__table--header text-white"
        >
         {{ $t("ticket.send") }}
        </button>
       </div>
      </div>
     </form>
    </div>
   </div>
  </div>
 </transition> -->
 <ComfirmationDialog
  v-if="this.showMessage"
  :messageToShow="this.messageToShow"
  :subMessageToShow="this.subMessageToShow" />
</template>

<script>
import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";
import axios from "axios";
import { mapGetters } from "vuex";
import ComfirmationDialog from "../components/ConfirmationDialog.vue";
import { SearchIcon } from "@heroicons/vue/outline";

const dest_type = [
 { name: "extension", value: "extension" },
 { name: "voicemail", value: "voicemail" },
 //  { name: "svi", value: "svi" },
 { name: "queue", value: "callQueue" },
 //  { name: "call flow", value: "callFlow" },
 { name: "end call", value: "endCall" },
 { name: "external number", value: "externalNumber" },
];

const account = localStorage.getItem("account");
export default {
 name: "CreateNewCallQueue",
 props: [
  "title",
  "subTitle",
  "callQueuesGroups",
  "closeFormButton",
  "editData",
  "openUpdateForm",
  "callQueuesOptions",
 ],
 components: {
  ComfirmationDialog,
  SearchIcon,
  VueSelect,
 },
 data() {
  return {
   phoneError: null,
   searchDestNumber: "",
   destNumber: null,
   searchNumber: "",
   account,
   dest_type,
   selectOptions: [],
   selectOptionsNumbers: [],
   closeForm: true,
   destinationType: null,
   destination: null,
   type: "queue",
   form: {
    destination: null,
    number: "",
    name: "",
    code: "",
    call_queue_group_id: null,
    active: true,
   },
   showMessage: false,
   messageToShow: "",
   subMessageToShow: "",
   searchableTerm: "",
   extensionOptions: [],
   queueOptions: [],
   didOptions: [],
  };
 },
 methods: {
  postCallQueue() {
   if (this.title === this.$t("callqueue.newFormTitle")) {
    let data = {};
    this.form.number = this.searchNumber;
    if (this.type === "queue") {
     data = {
      number: this.form.number,
      name: this.form.name,
      code: this.form.code,
      call_queue_group_id: this.form.call_queue_group_id,
      type: this.type,
      active: this.form.active ? 1 : 0,
      default_destination: this.form.destination,
      default_destination_type: this.destinationType,
     };
    } else {
     data = {
      did_number: this.form.number,
      name: this.form.name,
      code: this.form.code,
      call_queue_group_id: this.form.call_queue_group_id,
      type: this.type,
      active: this.form.active ? 1 : 0,
      default_destination: this.form.destination,
      default_destination_type: this.destinationType,
     };
    }
    console.log("postCallQueue ~ data:", data);
    axios
     .post(
      `${this.$cookie.getCookie("API")}/api/v1/callQueues?customerAccount=${
       this.account
      }`,
      data,
      {
       headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     )
     .then(res => {
      console.log("postCallQueue ~ res:", res);
      let msg = this.$t("elementCreatedSuccessfully", {
       element: this.$t("callqueue"),
      });
      // this.$emit("activeBanner");
      // this.$emit("success", true);
      // this.$emit("successMsg", msg);
      // this.$emit("closeForm");

      // this.cleanForm();
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   } else {
    const options = {
     method: "POST",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroups`,
     params: {
      customerAccount: this.account,
      name: this.form.name,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };
    axios
     .request(options)
     .then(res => {
      let msg = this.$t("elementCreatedSuccessfully", {
       element: this.$t("callqueueGroup"),
      });
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      this.cleanForm();
      this.$emit("closeForm");

      this.$router.push({
       name: "CallQueuesGroupDetailExpert3cx",
       params: { account: this.account, id: res.data.id },
      });
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  putCallQueue(id) {
   if (this.title === this.$t("callqueue.updateFormTitle")) {
    let data = {};
    this.form.number = this.searchNumber;
    if (this.type === "queue") {
     data = {
      number: this.form.number,
      name: this.form.name,
      code: this.form.code,
      call_queue_group_id: this.form.call_queue_group_id,
      type: this.type,
      active: this.form.active ? 1 : 0,
      default_destination: this.form.destination,
      default_destination_type: this.destinationType,
     };
    } else {
     data = {
      did_number: this.form.number,
      name: this.form.name,
      code: this.form.code,
      call_queue_group_id: this.form.call_queue_group_id,
      type: this.type,
      active: this.form.active ? 1 : 0,
      default_destination: this.form.destination,
      default_destination_type: this.destinationType,
     };
    }
    axios
     .put(
      `${this.$cookie.getCookie(
       "API"
      )}/api/v1/callQueues/${id}?customerAccount=${this.account}`,
      data,
      {
       headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     )
     .then(res => {
      let msg = this.$t("elementUpdatedSuccessfully", {
       element: this.$t("callQueue"),
      });
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      this.$emit("closeForm");
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   } else {
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroups/${id}`,
     params: {
      customerAccount: this.account,
      name: this.form.name,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };

    axios
     .request(options)
     .then(res => {
      let msg = this.$t("elementUpdatedSuccessfully", {
       element: this.$t("callqueueGroup"),
      });
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      this.$emit("closeForm");
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
  cleanForm() {
   this.form.number = "";
   this.form.name = "";
   this.form.call_queue_group_id = null;
   this.form.code = "";
  },
  fillDest() {
   Object.keys(this.callQueuesOptions?.extensions).forEach(val => {
    let obj = {};
    obj["value"] = val;
    obj["label"] = this.callQueuesOptions?.extensions[val];
    this.extensionOptions.push(obj);
   });
   Object.keys(this.callQueuesOptions?.queues).forEach(val => {
    let objQueue = {};
    objQueue["value"] = val;
    objQueue["label"] = this.callQueuesOptions?.queues[val];
    this.queueOptions.push(objQueue);
   });
  },
  fillOptions() {
   let queues = [];
   let dids = [];
   if (this.callQueuesOptions) {
    Object.keys(this.callQueuesOptions?.queues).forEach(val => {
     let obj = {};
     obj["value"] = val;
     obj["label"] = this.callQueuesOptions?.queues[val];
     queues.push(obj);
    });
    Object.keys(this.callQueuesOptions?.dids).forEach(val => {
     let obj = {};
     obj["value"] = val;
     obj["label"] = this.callQueuesOptions?.dids[val];
     dids.push(obj);
    });
    switch (this.type) {
     case "did":
      this.selectOptionsNumbers = dids;
      break;
     case "queue":
      this.selectOptionsNumbers = queues;
      break;

     default:
      console.log("Unknown action");
      break;
    }
   }
  },
 },
 watch: {
  type: function (val) {
   this.selectOptionsNumbers = [];
   this.fillOptions();
  },
  searchDestNumber: function (val) {
   if (this.destinationType === "externalNumber") {
    this.validatePhoneNumber();
    this.destNumber = val;
    this.form.destination = val;
   } else {
    this.destNumber = val?.value;
    this.form.destination = val?.value;
   }
  },
  destinationType: function (val) {
   switch (val) {
    case "extension":
     this.selectOptions = this.extensionOptions;
     break;
    case "voicemail":
     this.selectOptions = this.extensionOptions;
     break;
    case "svi":
     // this.selectOptions = extensions;
     break;
    case "callQueue":
     this.selectOptions = this.queueOptions;
     break;
    case "callFlow":
     // this.selectOptions = extensions;
     break;
    case "endCall":
     // this.selectOptions = extensions;
     break;
    case "externalNumber":
     this.searchDestNumber = "";
     // this.selectOptions = extensions;
     break;
    default:
     console.log("Unknown action");
     break;
   }
  },
 },
 mounted() {
  if (this.callQueuesOptions) {
   this.fillOptions();
   this.fillDest();
  }
  if (this.openUpdateForm) {
   console.log("mounted ~ this.editData:", this.editData);
   this.form.number = this.editData.number;
   this.form.name = this.editData.name;
   this.form.code = this.editData.code;
   this.type = this.editData.type;
   this.default_destination = this.editData.default_destination;
   this.default_destination_type = this.editData.default_destination_type;
   this.form.call_queue_group_id = this.editData.call_queue_group_id
    ? this.editData.call_queue_group_id
    : null;
   this.destinationType = this.editData.default_destination_type;
   this.searchDestNumber = this.editData.default_destination;
   this.destNumber = this.editData.default_destination;
   this.searchNumber = this.editData.number;
  }
 },
};
</script>

<style scoped>
.is-invalid {
 border-color: red;
}
.error {
 color: red;
 font-size: 0.875em;
}
</style>
